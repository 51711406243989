.blogger__blog-container_article {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* background: #1e293d; */
}

.blogger__blog-container_article-image {
    width: 100%;
    height: 100%;
}

.blogger__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.blogger__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    height: 100%;
}

.blogger__blog-container_article-content p {
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 11.649px;
    line-height: 35px;
    color: #cfab48;
}
.blogger__blog-container_article-content h3 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 25.11px;
    line-height: 30px;
    color: #FDDA7D;
    margin-bottom: 5rem;
    cursor: pointer;
}

.blogger__blog-container_article-content p:last-child {
    cursor: pointer;
}

@media screen and (max-width: 550px) {
    .blogger__blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25px;
    }
}