.foot__footer {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
}

.foot__footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
}

.foot__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 42px;
    line-height: 75px;
    text-align: center;
    letter-spacing: -0.04em;
}

.foot__footer-button:hover{
    background: rgb(8, 52, 110);
    border: 1px solid rgb(8, 52, 110);
}
.foot__footer-button {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    border: 1px solid whitesmoke;
    text-align: center;

    margin-bottom: 10rem;
    cursor: pointer;

}
.foot__footer-button p {
    box-sizing: border-box;
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 21px;
    color: whitesmoke;
    word-spacing: 2px;
}

.foot__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.foot__footer-copyright p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 14px;
    color: whitesmoke;
}

.branding__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.branding__brand div {
    flex: 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 850px){
    .foot__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .foot__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .foot__footer-button p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .foot__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}