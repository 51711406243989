* { 
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

a:hover{
    color: rgb(109, 255, 236);
}
a {
    transition: 0.15s;
    color: unset;
    text-decoration: none;
}


.gradient__bg {
    background: rgb(88,192,255);
    background: -moz-linear-gradient(171deg, rgba(88,192,255,1) 22%, rgba(117,82,242,1) 79%);
    background: -webkit-linear-gradient(171deg, rgba(88,192,255,1) 22%, rgba(117,82,242,1) 79%);
    background: linear-gradient(171deg, rgba(88,192,255,1) 22%, rgba(117,82,242,1) 79%);
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.social-links {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
}

.social-buttons {
    display: block;
    border-style: solid;
    border-color: rgba(0, 255, 255, 0.684);
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 12%;
    transition-duration: 0.5s;

}

.social-buttons:hover {
    border-color: aqua;
    transition-duration: 0.25s;
    background-color: rgba(0, 255, 255, 0.145);
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
  

@media screen and (max-width: 700px) { /* tablets or bigger phones */
    .section__padding {
        padding: 4rem;
    }

    .section__margin {
        margin: 4rem;
    }
}

@media screen and (max-width: 550px) { /* mobile */
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margin {
        margin: 4rem 2rem;
    }
}