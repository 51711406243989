.navigation__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.navigation__navbar-links {
    flex: 1;
    display: flex;
    justify-content: 'flex-start';
    align-items: center;
}

.navigation__navbar-links_logo {
    margin-right: 2rem;
}

.navigation__navbar-links_logo img {
    width: 62.56px;
    height: 56.02px;
}

.navigation__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.navigation__navbar-contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navigation__navbar-links_container p,
.navigation__navbar-contact p,
.navigation__navbar-menu_container p {
    color: whitesmoke;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;

    margin: 0 1rem;
    cursor: pointer;
}

.navigation__navbar-contact button,
.navigation__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: whitesmoke;
    background: #2054ff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.navigation__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navigation__navbar-menu svg {
    cursor: pointer;
}

.navigation__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.navigation__navbar-menu_container p {
    margin: 1rem 0;
}

.navigation__navbar-menu_container-links-contact {
    display: none;
}

@media screen and (max-width: 1150px) { /* big screens */
    .navigation__navbar-links_container {
        display: none;
    }

    .navigation__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) { /* tablets */
    .navigation__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) { /* mobile */
    .navigation__navbar {
        padding: 2rem;
    }

    .navigation__navbar-contact {
        display: none;
    }

    .navigation__navbar-menu_container {
        top: 20px;
    }

    .navigation__navbar-menu_container-links-contact {
        display: block;
    }
}