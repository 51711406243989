.blogger__blog {
    display: flex;
    flex-direction: column;
    background-color: #B94429;
}

.blogger__blog-heading {
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
    color: #FDDA7D
}

.blogger__blog-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 52px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.blogger__blog-heading h4 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 22px;
    line-height: 35px;
    letter-spacing: -0.04em;
}

.blogger__blog-container {
    display: flex;
    flex-direction: row;
}

.blogger__blog-container_groupA {
    flex: 0.75;
    margin-right: 2rem;
}

.blogger__blog-container_groupB {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

@media screen and (max-width: 990px) {
    .blogger__blog-container {
        flex-direction: column-reverse;
    }

    .blogger__blog-container_groupA {
        margin: 2rem 0;
    }

    .blogger__blog-container_groupA .blogger__blog-container_article {
        width: 48px;
    }

    .blogger__blog-container_groupA .blogger__blog-container_article-image {
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .blogger__blog-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .blogger__blog-container_groupA .blogger__blog-container_article {
        width: 100%;
    }

    .blogger__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }

    .blogger__blog-heading h4 {
        font-size: 19px;
        line-height: 24.27px;
    }
}

@media screen and (max-width: 550px) {
    .blogger__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .blogger__blog-heading h4 {
        font-size: 14px;
        line-height: 19.6px;
    }
}