@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(90deg, rgb(48, 164, 253) 0%, rgb(55, 255, 182) 76%);
  --gradient-bar: linear-gradient(90deg, rgba(60, 172, 255, 1) 0%, rgba(103, 244, 193, 1) 76%);
  --spotify-gradient-bar: linear-gradient(90deg, #06877d 100%, #7df2c5 100%);

  /* --color-bg: rgb(12, 43, 126); */
  --color-bg: #3e9e8e;
  --color-footer: #031B34;
  --color-blog: #042c54;
  --color-text: #bde9ff;
  --color-subtext: #3cf8ff;
}