.feats__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: rgb(40, 142, 90); 
}

.feats__features-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin-right: 5rem;
}

.work-experience-image {
    background-color: rgb(255, 250, 198); 
    padding: 1rem;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.work-experience-image img {
    width: 100%;
    height: 50%;
}

.feats__features-heading h1 {
    font-family: var(--font-family);
    color: whitesmoke;
    font-weight: 800;
    font-size: 34px;
}

.feats__features-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #fff8b6;
    margin-bottom: 2rem;
    cursor: pointer;
}

.feats__features-container {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}


@media screen and (max-width: 990px) {
    .feats__features {
        flex-direction: column;
    }

    .feats__features-heading {
        margin: 0 0 2rem 0;
    }
}

@media screen and (max-width: 550px) {
    .feats__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}